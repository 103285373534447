import { Injectable, OnDestroy } from '@angular/core';
import { PinpointClient, PutEventsCommand, UpdateEndpointCommand } from '@aws-sdk/client-pinpoint';
import { AuthService } from '@auth/auth.service';
import { Subscription } from 'rxjs';
import { PatientService } from '../patient/patient.service';
import { MetadataService } from '../metadata/metadata.service';
import { v4 as uuidv4 } from 'uuid';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { environment } from 'src/environments/environment';

type SpecialParams = "_session.start" | "_session.stop";

@Injectable({
  providedIn: 'root'
})
export class PinpointService extends ShowTimeApiService implements OnDestroy {
  private awsCredentialsSubscription: Subscription;
  private pinpointClient: PinpointClient;
  private pinpointAppId: string;
  // false = off, true = on. 'false' disables any calls to Cognito or logging of Pinpoint events. 
  public pinpointFlag: boolean = environment.pinpointFlag;
  private DEFAULT_REGION: string;
  public user_id: string;

  constructor(
    private authService: AuthService,
    private patientService: PatientService,
    private metadataService: MetadataService,
    protected alertService: AlertService,
  ) {
    super(alertService);
    
    // Listen for AWS credentials. When they are available, initialize the Pinpoint client.
    this.awsCredentialsSubscription = this.authService.awsCredentials$.subscribe(credentials => {

      if (credentials) {
        this.initializePinpointClient(credentials).catch(error => {
          this.showErrorAsync(error, { disableAlert: true });
        });
      }
    });
  }

  // runs several checks to ensure mandatory steps have been taken to allow Pinpoint actions to proceed
  private async canProceedWithPinpointActions(endpointId: string): Promise<boolean> {
      // If no endpointId passed in or user_id not set, get from auth service
      if (!endpointId && !this.user_id) {
          const userInfo = await this.authService.getUserInfo();
          this.user_id = userInfo?.sub;
      } else {
          this.user_id = endpointId || this.user_id;
      }
    // console.log("pinpoint service's user_id:", this.user_id);

    const countryId = this.metadataService.getSelectedCountryId();
    const isSmartCoachingSupportedInCountry = await this.isSmartCoachingSupportedInCountry(countryId);
    
    if (this.pinpointFlag) {
      if (isSmartCoachingSupportedInCountry) {
        const isSmartCoachingEnabledForPatient = await this.isSmartCoachingEnabledForPatient();

        if (isSmartCoachingEnabledForPatient) {
          await this.authService.checkAndRefreshAWSCredentials();

          if (!this.pinpointClient) {
            // ensure AWS credentials exist and are not expired. When updated, this will trigger awsCredentialsSubscription above, which will initialize the pinpointClient.
            await this.authService.checkAndRefreshAWSCredentials();
            if (!this.pinpointClient) {
              return false;
            }
          }

          if (!this.user_id) {
            const userInfo = await this.authService.getUserInfo();
            const userSub = userInfo.sub;
            this.user_id = userSub;
            if (!this.user_id) {
              return false;
            }
          }

          return true;
        }
      }
    }
      return false;
  }

  private async initializePinpointClient(credentials): Promise<void> {
    try {
      const metadata = await this.metadataService.getMetadataFromApi().toPromise();
      this.DEFAULT_REGION = metadata.instanceInfo.smartCoachingAWSRegion;
      this.pinpointAppId = metadata.instanceInfo.smartCoachingPinpointAppId;

      if (this.DEFAULT_REGION && this.DEFAULT_REGION !== "TBD" && 
      this.pinpointAppId && this.pinpointAppId !== "TBD") {
        this.pinpointClient = new PinpointClient({
          region: this.DEFAULT_REGION,
          credentials: {
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretKey,
            sessionToken: credentials.SessionToken
          }
        });
      } else {
        throw new Error("Default Region and/or Pinpoint Application Id were not set.");
      }
    } catch (error) {
        await this.showErrorAsync(error, { disableAlert: true });
    }
  }

  ngOnDestroy() {
    this.awsCredentialsSubscription.unsubscribe();
  }

  // endpointId should be Okta user_id (tokens.token.claims.sub)
  async updateEndpoint(endpointId: string, specialParams?: SpecialParams): Promise<void> {

    // console.log('updateEndpoint called with:', {
    //   incomingEndpointId: endpointId,
    //   currentUserId: this.user_id,
    //   specialParams
    // });

    if (await this.canProceedWithPinpointActions(this.user_id)) {
      // console.log("pinpointChecks passed, proceeding with updateEndpoint");
      try {
        this.user_id = endpointId;

            const params = {
              ApplicationId: this.pinpointAppId,
              EndpointId: endpointId,
              EndpointRequest: {
                // ChannelType: channelType
              }
            };

            const command = new UpdateEndpointCommand(params);

            await this.pinpointClient.send(command);

            // send putEvents logged in event only after updateEndpoint completes, without holding up the login process.
            if(specialParams === "_session.start") {
              this.putEvents("_session.start", new Date().toISOString()).then(() => {
                // console.log("Pinpont endpoint updated, putEvents(_session.start) sent successfully.");
              }).catch(err => {
                // console.error("Error sending Pinpoint event:", err);
                this.showErrorAsync(err, { disableAlert: true });
              });
            }
      } catch (error) {
        this.showErrorAsync(error, { disableAlert: true });
      }
    } // if canProceedWithPinpointActions() checks fail, don't proceed with Pinpoint actions, silently
  }

  async trackCardImpression(smartCoachingId: string): Promise<void> {
      // console.log('trackCardImpression state:', {
      //   smartCoachingId,
      //   currentUserId: this.user_id
      // });
  
    if (await this.canProceedWithPinpointActions(this.user_id)) {
      try {
        // console.log("Sending card impression event to Pinpoint");
        await this.putEvents("custom.card.view", new Date().toISOString(), {
          smart_coaching_id: smartCoachingId
        });
      } catch (error) {
        // console.error("Error sending card impression event:", error);
        this.showErrorAsync(error, { disableAlert: true });
      }
    }
  }

  async trackCardTap(smartCoachingId: string, buttonType?: string): Promise<void> {
    if (await this.canProceedWithPinpointActions(this.user_id)) {
      try {
        const attributes: Record<string, string> = {
          smart_coaching_id: smartCoachingId
        };
        
        // Only add button type if provided
        if (buttonType) {
          attributes.smart_coaching_button_type = buttonType;
        }
  
        await this.putEvents(
          "custom.card.button.tap", 
          new Date().toISOString(),
          attributes
        );
      } catch (error) {
        this.showErrorAsync(error, { disableAlert: true });
      }
    }
  }

    async trackArticleView(smartCoachingId: string): Promise<void> {
      if (await this.canProceedWithPinpointActions(this.user_id)) {
        try {
          // console.log('Tracking article view for card:', {
          //   smartCoachingId: smartCoachingId,
          // });
          await this.putEvents("custom.article.open", new Date().toISOString(), {
            smart_coaching_id: smartCoachingId
          });
        } catch (error) {
          this.showErrorAsync(error, { disableAlert: true });
        }
      }
    }
  
    async trackVideoView(smartCoachingId: string): Promise<void> {
      if (await this.canProceedWithPinpointActions(this.user_id)) {
        try {
          // console.log('Tracking video play for card:', {
          //   smartCoachingId: smartCoachingId,
          // });
          await this.putEvents("custom.video.open", new Date().toISOString(), {
            smart_coaching_id: smartCoachingId
          });
        } catch (error) {
          this.showErrorAsync(error, { disableAlert: true });
        }
      }
    }

  async putEvents(eventType: string, timestamp: string, attributes: Record<string, string> = {}): Promise<void> {
    const params = {
      ApplicationId: this.pinpointAppId, // Your Pinpoint application ID
      EventsRequest: {
        BatchItem: {
          //Mobile apps use Amplify which generates a UUID for the below key, presumably to uniquely identify batches. But web dos not utilize batches, and using a UUID below causes inflated Daily Active Endpoints for web. Keeping this as user_id for now so that subsequent logins of the same user don't generate new Daily Active Endpoints for each session in the same day (does it for the ios/android apps?)
          [this.user_id]: { // Endpoint ID as the key
            // consider sending empty Endpoint object if not needed
            Endpoint: {
              EndpointId: this.user_id,
              Demographic: {
                  Platform: "web",
              },
              // EffectiveDate: new Date().toISOString(), // The time when the endpoint was updated
              Attributes: {},
              Metrics: {},
              User: {
                  UserId: this.user_id
              },
              ApplicationId: this.pinpointAppId,
            },
            Events: {
              // Your UUID unique event identifier as the key
              [uuidv4()]: {
                EventType: eventType, // Required: Your event type
                Timestamp: timestamp, // Required: Event timestamp. Should equal precisely when the event occurred, which is why we define it in the putEvents call.
                Attributes: {
                  user_id: this.user_id,
                  ...attributes
                },
                // Other fields like AppPackageName, AppVersionCode, etc., if needed
              }
            }
          }
        }
      }
    };
    // console.log("pinpointChecks passed, proceeding with putEvents(eventType, timestamp)", eventType, timestamp);
    if (await this.canProceedWithPinpointActions(this.user_id)) {
      try {
        // Ensure AWS credentials are valid before sending events
        await this.authService.checkAndRefreshAWSCredentials();
        // console.log("AWS credentials are valid. Sending Pinpoint event."); 
        await this.pinpointClient.send(new PutEventsCommand(params));
              // console.log("Pinpoint event sent successfully."); // Log after successful event send
        } catch (error) {
              if (error.name === 'NotAuthorizedException') {
                // console.warn("AWS credentials expired. Attempting to refresh tokens...");
          
                // Refresh Okta tokens and AWS credentials if authorization fails
                await this.authService.refreshOktaTokensIfNeeded();
                await this.authService.checkAndRefreshAWSCredentials();
          
                try {
                  await this.pinpointClient.send(new PutEventsCommand(params));
                  // console.log("Pinpoint event retried successfully after refreshing tokens.");
                } catch (retryError) {
                  this.showErrorAsync(retryError, { disableAlert: true });
                }
              } else {
                this.showErrorAsync(error, { disableAlert: true });
              }
            }
          }
        }  

  async isSmartCoachingEnabledForPatient(): Promise<boolean> {
    const patientDataArray = await this.patientService.getPatient().toPromise();
    const smartCoachingEnabled = patientDataArray[0]?.patient?.smartCoachingEnabled;
    // console.log("pinpoint Service's patientDataArray", patientDataArray);
    // console.log("pinpiont Service's smartCoachingEnabled", smartCoachingEnabled);
    return smartCoachingEnabled ?? false; // Return false if undefined or null
  }

  async isSmartCoachingSupportedInCountry(countryCode: string): Promise<boolean> {
    const metadata = await this.metadataService.getMetadataFromApi().toPromise();
    const country = metadata.countries.find(c => c.code === countryCode);
    // console.log("pinpoint Service's country", country);
    // console.log("pinpoint Service's isSmartCoachingSupported", country?.isSmartCoachingSupported);
    return country?.isSmartCoachingSupported ?? false; // Return false if undefined or null
  }

}
