export function getFirebaseConfig(isEU: boolean): { [key: string]: string | boolean } {
  if (!isEU) {
    return {
      apiKey: 'AIzaSyDmgAAltMhhNAIU3_PtwNLGD3AcOroKMbk',
      authDomain: 'myair-dev.firebaseapp.com',
      databaseURL: 'https://myair-dev.firebaseio.com',
      projectId: 'myair-dev',
      storageBucket: 'myair-dev.appspot.com',
      messagingSenderId: '687537107651',
      appId: '1:687537107651:web:6e3951ab5fc011c8446876',
      measurementId: 'G-5NG0PCN2MZ',
    };
  } else {
    return {
      apiKey: 'AIzaSyCt1_hqXfdr68bOaYp6G1EjGg-pfY1YoZc',
      authDomain: 'myair-eu-dev.firebaseapp.com',
      databaseURL: 'https://myair-eu-dev.firebaseio.com',
      projectId: 'myair-eu-dev',
      messagingSenderId: '113193916109',
      storageBucket: 'myair-eu-dev.appspot.com',
      appId: '1:113193916109:web:03a892db8b384144877b84',
      measurementId: 'G-872L4Q6TM4',
    };
  }
}
